import React from 'react';
import { graphql } from 'gatsby';
import Event from '../components/Event';

const IndexPage = ({ data }) => {
  const events = data.allDatoCmsEvent.edges;
  return (
    <main>
      {events.map(({ node }) => (
        <Event {...node} />
      ))}
    </main>
  );
};

export const query = graphql`
  query events {
    allDatoCmsEvent {
      edges {
        node {
          id
          name
          description
          datetime
        }
      }
    }
  }
`;

export default IndexPage;
