import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  margin: 1rem;
  padding: 1rem;
  border: 1px solid blue;
`;

const Event = p => (
  <Wrapper key={p.id}>
    <p>{p.name}</p>
    <p style={{whiteSpace: "pre-wrap"}}>{p.description}</p>
    <p>{p.datetime}</p>
  </Wrapper>
);

export default Event;
